import React, {useState} from 'react';
import Menu from '../Menu/Menu';
import {Turn as Hamburger} from 'hamburger-react';
import {Container, Row, Col} from 'react-bootstrap';
import fullLogo from '../../assets/images/fullLogo.svg';
import fullLogoBlack from '../../assets/images/fullLogoBlack.svg';
import {useRecoilValue} from 'recoil';
import {isMobileSelector, ModeSelector} from '../../recoil/selectors'
import styles from './Header.module.scss';
import {Link} from 'gatsby'

const Header = () => {
    const isMobile = useRecoilValue(isMobileSelector);
    const mode = useRecoilValue(ModeSelector);
    
    const [isOpen, setOpen] = useState(false)
    
    return (
        <>
        <Container fluid className={styles.ContainerHeader} id="Header">
            <Row className={styles.RowHeader}>
                <Col className={styles.ColLogo}>
                    <Link to='/'>
                    {mode === 'dark' || !mode 
                        ? <img alt="Full logo" src={fullLogo} className={styles.imgHeader}/>
                        : <img alt="Full logo" src={fullLogoBlack} className={styles.imgHeader}/>
                    }
                    </Link>
                </Col>
            </Row>
        </Container>
        <div className={styles.ColIconMenu}>
            {!isMobile && <p className={styles.textIconMenu}>
                {isOpen ? <span className={styles.CloseText}>Cerrar</span> 
                        : <span className={styles.OpenText}>Menu</span>}
            </p>}
            <Hamburger toggled={isOpen} toggle={setOpen} aria-label="Menu" color={(isMobile && mode === 'dark' ? "#fff" : "#9E38FC")} size={22}/>
        </div>
        <Menu isOpen={isOpen} setOpen={setOpen}/>
        </>
    )

}

export default Header