import React, { useEffect } from "react";
import { Container, Row, Col, Button, ListGroup } from "react-bootstrap";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import styles from "./Menu.module.scss";
import logo from "../../assets/images/icon.png";
import arrowViolet from "../../assets/images/arrowViolet.png";
import facebook from "../../assets/images/Facebook.svg";
import instagram from "../../assets/images/Instagram.svg";
import linkedin from "../../assets/images/Linkedin.svg";
import facebookBlack from "../../assets/images/facebookBlack.svg";
import instagramBlack from "../../assets/images/instagramBlack.svg";
import linkedinBlack from "../../assets/images/linkedinBlack.svg";
import { useRecoilValue, useRecoilState } from "recoil";
import { isMobileSelector } from "../../recoil/selectors";
import { Lenguage, Mode } from "../../recoil/atoms";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import { IsMobileDevice } from "../../utils/functions";
import loadable from "@loadable/component";
import Div100vh from "../../utils/div100";

const Menu = ({ isOpen, setOpen }) => {
  const mobile = useRecoilValue(isMobileSelector);
  const [leng, setLeng] = useRecoilState(Lenguage);
  const [mode, setMode] = useRecoilState(Mode);

  useEffect(() => {
    if (isOpen) {
      document.getElementById("ContainerMenu").style.visibility = "visible";
    }
  });

  useEffect(() => {
    const menuHover = loadable(() => import("../../lib/menuHover/index"));
    !IsMobileDevice() &&
      menuHover
        .load()
        .then((data) => data.loadMenuHover())
        .catch((data) => console.log("error menuHover: ", data));
  }, []);

  const handlerMode = (toggleTheme, theme) => {
    toggleTheme(theme);
    setMode(theme);
  };

  return (
    <Div100vh className={isOpen ? styles.slideIn : styles.slideOut}>
      <Container fluid className={styles.ContainerMenu} id="ContainerMenu">
        <Row className={styles.RowContent}>
          {/* header */}
          <Row className={styles.RowHeaderMenu}>
            <Col xs="6" className="p-0">
              <img alt="logo" src={logo} className={styles.logoMenu} />
            </Col>
          </Row>
          {/* body */}
          <Row className={styles.RowBody}>
            {!mobile ? (
              /*showLinks &&*/
              <Col className="p-0">
                <nav className="menuNav">
                  <Container fluid>
                    <Row className="justify-content-center m-0 p-0">
                      <Col onClick={(e) => setOpen(false)} xs="auto">
                        <Link to="/" className={`${styles.Link} menu__item`}>
                          <h1>Home</h1>
                        </Link>
                      </Col>
                    </Row>
                   {/* <Row className="justify-content-center m-0 p-0">
                      <Col onClick={(e) => setOpen(false)} xs="auto">
                        <Link
                          to="/blockchain"
                          className={`${styles.Link} menu__item`}
                        >
                          <h1>Blockchain</h1>
                        </Link>
                      </Col>
                    </Row>*/}

                    <Row className="justify-content-center m-0 p-0">
                      <Col onClick={(e) => setOpen(false)} xs="auto">
                        <AnchorLink
                          to="/#hub"
                          className={`${styles.Link} menu__item`}
                        >
                          <h1>Nosotros</h1>
                        </AnchorLink>
                      </Col>
                    </Row>
                    <Row className="justify-content-center m-0 p-0">
                      <Col onClick={(e) => setOpen(false)} xs="auto">
                        <Link
                          to="/projects"
                          className={`${styles.Link} menu__item`}
                        >
                          <h1>Portfolio</h1>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="justify-content-center m-0 p-0">
                      <Col onClick={(e) => setOpen(false)} xs="auto">
                        <Link
                          to="/contact"
                          state={{
                            title1: "Sumate al",
                            title2: "equipo",
                            text:
                              "Trabajamos con equipos dinámicos y on demand para entregar a nuestros clientes la mejor experiencia.",
                            mode: "CV",
                          }}
                          className={`${styles.Link} menu__item`}
                        >
                          <h1>Sumate</h1>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="justify-content-center m-0 p-0">
                      <Col onClick={(e) => setOpen(false)} xs="auto">
                        <AnchorLink
                          to="/contact"
                          className={`${styles.Link} menu__item`}
                        >
                          <h1>Contacto</h1>
                        </AnchorLink>
                      </Col>
                    </Row>
                  </Container>
                </nav>
                <svg
                  className="cursor"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                >
                  <circle className="cursor__inner" cx="20" cy="20" r="10" />
                </svg>
              </Col>
            ) : (
              <Col>
                <Row className="justify-content-center">
                  <Col onClick={(e) => setOpen(false)} xs="auto">
                    <Link to="/" className={styles.Link}>
                      <h1>Home</h1>
                    </Link>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col onClick={(e) => setOpen(false)} xs="auto">
                    <Link to="/blockchain" className={styles.Link}>
                      <h1>Blockchain</h1>
                    </Link>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col onClick={(e) => setOpen(false)} xs="auto">
                    <AnchorLink to="/#hub" className={styles.Link}>
                      <h1>Nosotros</h1>
                    </AnchorLink>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col onClick={(e) => setOpen(false)} xs="auto">
                    <Link to="/projects" className={styles.Link}>
                      <h1>Portfolio</h1>
                    </Link>
                  </Col>
                </Row>
                <Row
                  onClick={(e) => setOpen(false)}
                  className="justify-content-center"
                >
                  <Col xs="auto">
                    <Link
                      to="/contact"
                      state={{
                        title1: "Sumate al",
                        title2: "equipo",
                        text:
                          "Trabajamos con equipos dinámicos y on demand para entregar a nuestros clientes la mejor experiencia.",
                        mode: "CV",
                      }}
                      className={styles.Link}
                    >
                      <h1>Sumate</h1>
                    </Link>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col onClick={(e) => setOpen(false)} xs="auto">
                    <AnchorLink to="/contact" className={styles.Link}>
                      <h1>Contacto</h1>
                    </AnchorLink>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          {/* footer */}
          <Row className={styles.RowFooterMenu}>
            {/* mode */}
            {mobile && (
              <Col xs="12" className={styles.ColMode}>
                <ThemeToggler>
                  {({ theme, toggleTheme }) => (
                    <Col xs="auto" className={styles.ColButtons}>
                      {/* {console.log("theme: ", theme)} */}
                      <Button
                        active={mode === "dark" ? true : false}
                        onClick={(e) => handlerMode(toggleTheme, "dark")}
                        className={styles.Button}
                      >
                        dark mode
                      </Button>
                      <span className={styles.SeparatorButton}>&bull;</span>
                      <Button
                        active={mode === "light" ? true : false}
                        onClick={(e) => handlerMode(toggleTheme, "light")}
                        className={styles.Button}
                      >
                        light mode
                      </Button>
                    </Col>
                  )}
                </ThemeToggler>
              </Col>
            )}
            {/* lenguage */}
            <Col md="4" xs="12" className={styles.ColLeng}>
              <ListGroup
                horizontal
                className="justify-content-center align-items-center"
              >
                <ListGroup.Item
                  active={leng === "es" ? true : false}
                  onClick={(e) => setLeng("es")}
                  className={styles.ItemButton}
                >
                  es
                </ListGroup.Item>
                <span className={styles.SeparatorButton}>|</span>
                <ListGroup.Item
                  active={leng === "en" ? true : false}
                  onClick={(e) => setLeng("en")}
                  className={styles.ItemButton}
                >
                  en
                </ListGroup.Item>
              </ListGroup>
            </Col>
            {/* info */}
            {!mobile && (
              <Col md="4" className={styles.ColCenter}>
                <Link
                  to="/contact"
                  state={{
                    title1: "iniciar un",
                    title2: "proyecto",
                    text: "Contanos tu idea para iniciar un proyecto",
                    mode: "proyect",
                  }}
                  className={styles.ContainerLink}
                >
                  <img
                    alt="arrow"
                    src={arrowViolet}
                    className={styles.ArrowBefore}
                  />
                  <p>Iniciar un Proyecto</p>
                  <img
                    alt="arrow"
                    src={arrowViolet}
                    className={styles.ArrowAfter}
                  />
                </Link>
              </Col>
            )}
            {/* socials */}
            <Col md="4" xs="12" className={styles.ColSocials}>
              {mode === "dark" ? (
                <>
                  <a
                    href="https://www.linkedin.com/company/grupo-itesa/about/"
                    target="__blank"
                    className={styles.ContainerIcon}
                  >
                    <div className={styles.IconBorder}>
                      <img
                        alt="linkedin"
                        src={linkedin}
                        className={styles.Icons}
                        style={{ marginLeft: "2px" }}
                      />
                    </div>
                  </a>

                  <a
                    href="#facebook"
                    target="__blank"
                    className={styles.ContainerIcon}
                  >
                    <div className={styles.IconBorder}>
                      <img
                        alt="facebook"
                        src={facebook}
                        className={styles.Icons}
                      />
                    </div>
                  </a>

                  <a
                    href="https://www.instagram.com/itesainnovation/"
                    target="__blank"
                    className={styles.ContainerIcon}
                  >
                    <div className={styles.IconBorder}>
                      <img
                        alt="instagram"
                        src={instagram}
                        className={styles.Icons}
                      />
                    </div>
                  </a>
                </>
              ) : (
                <>
                  <a
                    href="https://www.linkedin.com/company/grupo-itesa/about/"
                    target="__blank"
                    className={styles.ContainerIcon}
                  >
                    <div className={styles.IconBorder}>
                      <img
                        alt="linkedin"
                        src={linkedinBlack}
                        className={styles.Icons}
                        style={{ marginLeft: "2px" }}
                      />
                    </div>
                  </a>

                  <a
                    href="#facebook"
                    target="__blank"
                    className={styles.ContainerIcon}
                  >
                    <div className={styles.IconBorder}>
                      <img
                        alt="facebook"
                        src={facebookBlack}
                        className={styles.Icons}
                      />
                    </div>
                  </a>

                  <a
                    href="https://www.instagram.com/itesainnovation/"
                    target="__blank"
                    className={styles.ContainerIcon}
                  >
                    <div className={styles.IconBorder}>
                      <img
                        alt="instagram"
                        src={instagramBlack}
                        className={styles.Icons}
                      />
                    </div>
                  </a>
                </>
              )}
            </Col>
          </Row>
        </Row>
      </Container>
    </Div100vh>
  );
};

export default Menu;
