import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import styles from './Footer.module.scss';
import iconItesa from '../../assets/images/icon.png';
import arrowUp from '../../assets/images/arrowUp.png';
import arrowUpGray from '../../assets/images/arrowUpGray.png';
import arrowUpBlack from '../../assets/images/arrowUpBlack.png';
import facebook from '../../assets/images/Facebook.svg';
import instagram from '../../assets/images/Instagram.svg';
import linkedin from '../../assets/images/Linkedin.svg';
import facebookBlack from '../../assets/images/facebookBlack.svg';
import instagramBlack from '../../assets/images/instagramBlack.svg';
import linkedinBlack from '../../assets/images/linkedinBlack.svg';
import {useRecoilValue} from 'recoil';
import {isMobileSelector, ModeSelector} from '../../recoil/selectors'

const Footer = () => {
    
    const isMobile = useRecoilValue(isMobileSelector);
    const mode = useRecoilValue(ModeSelector);

    return (
        <Container fluid className={styles.ContainerFooter} id="Footer">
            {/* arrow up mobile */}
            {isMobile && 
                <Button href="#" onClick= {e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })} className={styles.arrowUpMobile}>
                        {mode === 'dark'
                            ?   <img alt="arrow up mobile" src={arrowUpGray}/>
                            :   <img alt="arrow up mobile" src={arrowUpBlack} style={{filter: 'brightness(5.5)'}}/>
                        }
                </Button>
            }
            {/* icon & email */}
            <Row className={styles.RowIcon}>
                <Col xs='12' className={styles.ColIcon}>
                    <img alt="icon" src={iconItesa} className={styles.Icon}/>
                </Col>
                <Col xs='12' className={styles.ColEmail}>
                    <p className={styles.TextIcon}>hello@itesa.co</p>
                </Col>
            </Row>
            {/* socials & direction & arrow up */}
            <Row className={styles.RowInfo}>
                {/* socials */}
                <Col md={{span: 4, order: 1}} xs={{span: 12, order: 2}} className={styles.ColIcons}>
                    {mode === 'dark'
                        ?   <>
                            <a href="https://www.linkedin.com/company/grupo-itesa/about/" target="__blank" className={styles.ContainerIcon}>
                                <div className={styles.IconBorder}>
                                    <img alt="linkedin" src={linkedin} style={{marginLeft: '2px'}}/>
                                </div>
                            </a>

                            <a href="#facebook" target="__blank" className={styles.ContainerIcon}>
                                <div className={styles.IconBorder}>
                                    <img alt="facebook" src={facebook}/>
                                </div>
                            </a>

                            <a href="https://www.instagram.com/itesainnovation/" target="__blank" className={styles.ContainerIcon}>
                                <div className={styles.IconBorder}>
                                    <img alt="instagram" src={instagram}/>
                                </div>
                            </a>
                            </>
                        
                        :   <>
                            <a href="https://www.linkedin.com/company/grupo-itesa/about/" target="__blank" className={styles.ContainerIcon}>
                                <div className={styles.IconBorder}>
                                    <img alt="linkedin" src={linkedinBlack} style={{marginLeft: '2px'}}/>
                                </div>
                            </a>

                            <a href="#facebook" target="__blank" className={styles.ContainerIcon}>
                                <div className={styles.IconBorder}>
                                    <img alt="facebook" src={facebookBlack}/>
                                </div>
                            </a>

                            <a href="https://www.instagram.com/itesainnovation/" target="__blank" className={styles.ContainerIcon}>
                                <div className={styles.IconBorder}>
                                    <img alt="instagram" src={instagramBlack}/>
                                </div>
                            </a>
                            </>
                    }
                </Col>
                {/* direction */}
                <Col md={{span: 4, order: 2}} xs={{span: 12, order: 1}} className={styles.ColInfo}>
                    <Row  className="p-0 m-0">
                        <Col xs='12' className={styles.ColParagraph}><p className={styles.Mobile}>Itesa Innovation Company SAS </p></Col>
                        <Col xs='12' className={styles.ColParagraph}><p className={styles.Direction}>Cazadores 2482, CABA, Argentina </p></Col>
                    </Row>
                </Col>
                {/* arrow up */}
                <Col xs={{span: 4, order: 3}} className={styles.ColArrowUp}>
                    <Button onClick= {e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}
                        className={styles.Link}>
                        <p>Volver arriba</p>
                        {mode === 'dark'
                            ?   <img alt="arrow up" src={arrowUp}/>
                            :   <img alt="arrow up" src={arrowUpBlack}/>
                        }
                    </Button>
                </Col>
            </Row>
            {/* rights */}
            <Row className={styles.RowRights}>
                <Col className={styles.ColRights}>
                    <p>Todos los derechos reservados 2020 &copy; ITESA INNOVATION COMPANY SAS</p>
                </Col>
            </Row>
            {/* line violet */}
            <Row className={styles.RowLine}></Row>
        </Container>
    )

}

export default Footer