import React from 'react';
import Header from '../Header/Header';
import FloatFooter from '../FloatFooter/FloatFooter';
import Footer from '../Footer/Footer';


const Layout = ({children}) => (
    <>
        <Header/>
        <FloatFooter/>
        {children}
        <Footer/>
    </>
)

export default Layout