import React, {Fragment, useEffect} from 'react';
import {Col} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import {ModeSelector} from '../../recoil/selectors';
// import SelectTheme from '../SelectTheme/SelectTheme';
import loadable from '@loadable/component';

//img
import styles from './FloatFooter.module.scss';
import facebook from '../../assets/images/Facebook.svg';
import instagram from '../../assets/images/Instagram.svg';
import linkedin from '../../assets/images/Linkedin.svg';
import facebookBlack from '../../assets/images/facebookBlack.svg';
import instagramBlack from '../../assets/images/instagramBlack.svg';
import linkedinBlack from '../../assets/images/linkedinBlack.svg';
const SelectTheme = loadable(() => import('../SelectTheme/SelectTheme'))

const FloatFooter = () => {

    const mode = useRecoilValue(ModeSelector)

    useEffect(() => {
        const magneticButton = loadable(() => import('../../lib/magneticButton/index.js'))
        magneticButton.load();
    },[])

    return (
        <Fragment>
            <Col xs='auto' className={`${styles.ColIcons} magneticContent`}>
                <a href="https://www.linkedin.com/company/grupo-itesa/about/" target="__blank" className={`${styles.ContainerIcon} magneticBtn1`} id="linkedin">
                    <div className="button__deco button__deco--2"></div>
                    <div className="button__deco button__deco--1"></div>
                    <div className={`${styles.IconBorder} button__text magnet`}>
                            <img alt="linkedin" src={mode === 'dark' || !mode ? linkedin : linkedinBlack} className={`button__text-inner ${styles.Icons}`}/>
                    </div>
                </a>    

                <a href="#facebook" target="__blank" className={`${styles.ContainerIcon} magneticBtn2`}>
                    <div className="button__deco button__deco--2"></div>
                    <div className="button__deco button__deco--1"></div>
                    <div className={`${styles.IconBorder} button__text magnet`}>
                            <img alt="facebook" src={mode === 'dark' || !mode ? facebook : facebookBlack} className={`button__text-inner ${styles.Icons}`}/>
                    </div>
                </a>
                
                <a href="https://www.instagram.com/itesainnovation/" target="__blank" className={`${styles.ContainerIcon} magneticBtn3`}>
                    <div className="button__deco button__deco--2"></div>
                    <div className="button__deco button__deco--1"></div>
                    <div className={`${styles.IconBorder} button__text magnet`}>
                            <img alt="instagram" src={mode === 'dark' || !mode ? instagram : instagramBlack} className={`button__text-inner ${styles.Icons}`}/>
                    </div>
                </a>
                        
                <svg className="magneticCursor" width="25" height="25" viewBox="0 0 25 25" style={{visibility: "hidden"}}>
                    <circle className="cursor__inner" cx="12.5" cy="12.5" r="6.25"/>
                </svg>
            </Col>
            <SelectTheme/>
        </Fragment>
    )

}

export default FloatFooter