import {atom} from 'recoil';

export const isMobile = atom({
    key:'isMobile',
    default: false
}); 

export const Mode = atom({
    key:'Mode',
    default: 'dark'
}); 

export const Lenguage = atom({
    key:'Lenguage',
    default: 'es'
}); 