import {selector} from 'recoil';
import {isMobile, Mode, Lenguage} from './atoms';

export const isMobileSelector = selector({
    key:'isMobileSelector',
    get: ({get}) => (get(isMobile))
});

export const ModeSelector = selector({
    key:'ModeSelector',
    get: ({get}) => (get(Mode))
});

export const LenguageSelector = selector({
    key:'LenguageSelector',
    get: ({get}) => (get(Lenguage))
});